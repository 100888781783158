import React, { useState, useEffect } from "react"
import Fade from "react-reveal/Fade"
import { Link as GatsbyLink } from "gatsby"
import logo from "../../images/cider_logo.svg"
import { window } from "browser-monads"
import "../../styles/navbar.css"
import { Link, animateScroll as scroll } from "react-scroll"
import './style.scss'
import ContactForm from "../ContactForm"

let newTimer

// Header uses the best scroll-link component based on which page is being browsed
export const ConditionalLink = ({ speed, text, offset }) => {
	const [isHome] = useState(window.location.pathname == "/")
	let setOffset = offset > -100 ? offset : 30

	useEffect(() => {
		if (typeof window !== "undefined") {
			// eslint-disable-next-line global-require
			require("smooth-scroll")('a[href*="#"]')
		}
	})

	return isHome ? (
		// Home page header links "/"
		<Link
			activeClass="active"
			to={text.toLowerCase()}
			spy={true}
			smooth={true}
			hashSpy={true}
			offset={setOffset}
			duration={speed}
			className={`mx-5 hover:text-yellow transition duration-300 cursor-pointer`}
		>
			{text}
		</Link>
	) : (
			// Header links used other pages than home page (not "/")
			<GatsbyLink
				to={`/#${text.toLowerCase()}`}
				className={`mx-5 hover:text-yellow transition duration-300 cursor-pointer`}
			>
				{text}
			</GatsbyLink>
		)
}

const Header = () => {
	// Related to Mobile/Tablet Menu
	const [isOpen, setIsOpen] = useState(false)
    
	const [isModalOpen, setIsModalOpen] = useState(false)

	// Related to Header Show/Hide Animation
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset)
	const [visible, setVisible] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [delay, setDelay] = useState(false)

	const [home] = useState(window.location.pathname)

	useEffect(() => {
		if (!loaded) {
			setLoaded(true)
			window.addEventListener("scroll", handleScroll)
		}
	})

	function handleScroll() {
		clearTimeout(newTimer)
		window.removeEventListener("scroll", handleScroll)
		const currentScrollPos = window.pageYOffset
		if (currentScrollPos < prevScrollPos || currentScrollPos === 0) {
			setVisible(true)
		} else {
			setVisible(false)
		}
		setDelay(true)
		setPrevScrollPos(currentScrollPos)
	}

	useEffect(() => {
		if (delay) {
			setTimeout(() => {
				setDelay(false)
				window.addEventListener("scroll", handleScroll)
			}, 100)
		}
	})

	useEffect(() => {
		if (!delay) {
			newTimer = setTimeout(() => {
				setVisible(true)
			}, 500)
		}
	}, [delay])

	function toggleMenu() {
		let bodyEl = document.querySelector('body')
		bodyEl.classList.toggle('overflow-hidden')

		setIsOpen(!isOpen)
	}

	function hideMenu() {
		let bodyEl = document.querySelector('body')
		bodyEl.classList.remove('overflow-hidden')

		setIsOpen(false)
	}

  function openContactForm() {
    setIsModalOpen(true)
	setTimeout(() => {
		hideMenu()
	}, 500);
  }

  function closeContactForm() {
    setIsModalOpen(false)
  }

	let toggle = isOpen ? "opacity-100 z-10" : "translate-y-full opacity-75"
	let translate = visible ? "-translate-y-0" : "-translate-y-full"

	return (
		<header className={`header transform ${translate} w-full transition duration-300 ease-in fixed z-100`}>
			<div className="container">
				<div className="header__wrap">
					<Fade bottom duration={500} delay={150}>
						<div className="header__logo">
							<GatsbyLink to="/" onClick={hideMenu}>
								<img src={logo} />
							</GatsbyLink>
						</div>
					</Fade>
					<nav className={`header__nav ${isOpen && 'header__nav--visible-mob'}`}>
						<Fade bottom duration={500} delay={150}>
							<span className="header__nav-title">Menu</span>
						</Fade>
						<ul className="header__nav-list">
							<Fade bottom duration={500} delay={200}>
								<li className="header__nav-item">
									{home == "/" ? (
										<Link
											activeClass="active"
											to="why-custom"
											spy={true}
											smooth={true}
											hashSpy={true}
											offset={-60}
											duration={2300}
											onClick={hideMenu}
										>
											Why Custom
										</Link>
									) : (
											<GatsbyLink to="/#why-custom" onClick={hideMenu}>
												Why Custom
											</GatsbyLink>
										)}
								</li>
							</Fade>
							<Fade bottom duration={500} delay={250}>
								<li className="header__nav-item">
									<GatsbyLink
										to="/our-story"
										onClick={hideMenu}
										activeClassName="active"
									>
										Our Story
									</GatsbyLink>
								</li>
							</Fade>
							<Fade bottom duration={500} delay={300}>
								<li className="header__nav-item">
									<GatsbyLink
										to="/philosophy"
										onClick={hideMenu}
										activeClassName="active"
									>
										Philosophy
									</GatsbyLink>
								</li>
							</Fade>
							<Fade bottom duration={500} delay={350}>
								<li className="header__nav-item">
									<GatsbyLink
										to="/what-we-do"
										onClick={hideMenu}
										activeClassName="active"
									>
										What We Do
									</GatsbyLink>
								</li>
							</Fade>
							<Fade bottom duration={500} delay={400}>
								<li className="header__nav-item">
									<GatsbyLink
										to="/success-stories"
										onClick={hideMenu}
										activeClassName="active"
									>
										Success Stories
									</GatsbyLink>
								</li>
							</Fade>
							<Fade bottom duration={500} delay={450}>
								<li className="header__nav-item">
									<GatsbyLink
										to="/careers"
										onClick={hideMenu}
										activeClassName="active"
									>
										Careers
									</GatsbyLink>
								</li>
							</Fade>
							<Fade bottom duration={500} delay={500}>
								<li className="header__nav-item">
									<Link
                    					to="#"
										activeClass="active"
										className="btn contact"
                    					onClick={openContactForm}
									>
										Contact Us
                  					</Link>
								</li>
							</Fade>
						</ul>
            			<ContactForm isOpen={isModalOpen} onRequestClose={closeContactForm} />
					</nav>
					<Fade bottom duration={500} delay={200}>
						<div className="header__btn-menu" onClick={toggleMenu}>
							{isOpen ? "Close" : "Menu"}
						</div>
					</Fade>
				</div>
			</div>
		</header>
	)
}

export default Header
