import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import checkIcon from "../../images/check-circle.svg"; 
import './style.scss';

Modal.setAppElement('#___gatsby'); // Set app element for accessibility

const ContactModal = ({ isOpen, onRequestClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    company: '',
    jobTitle: '',
    message: '',
  });

  const [showValidationStatus, setShowValidationStatus] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const encode = data => {
		const formData = new FormData()
	  
		for (const key of Object.keys(data)) {
		  formData.append(key, data[key])
		}
	  
		return formData
	}

  const handleSubmit = (e) => {
    e.preventDefault();

    for (let key in formData) {
      if (!formData[key]) {
        setShowValidationStatus(true);
        return;
      }
    }
  
    fetch('/contact/submit', {
			method: 'POST',
			body: encode({
			  'form-name': 'Contact Form',
			  ...formData,
			}),
		})
		.then(() => {
      toast('Sent!', {
        duration: 2000,
        position: "top-center",
        icon: <img style={{height: "70%"}} src={checkIcon} alt="check" />,
        style: {
          padding: "15px",
          fontSize: "50px",
        }
      })
  
      handleClose();
    })
		.catch((error) => {
      toast.error('Error sending message. Please try again later.', {
        style: {
          textAlign: "center",
          fontSize: "24px",
        }
      })
    })
  };

  const handleClose = () => {
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      company: '',
      jobTitle: '',
      message: '',
    });
    setShowValidationStatus(false);
    onRequestClose();
  };

  const getGroupClassNames = (name) => {
    return 'inputGroup ' + (showValidationStatus && !formData[name] ? 'invalid' : '');
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Contact Form"
      className="contact-modal"
      overlayClassName="contact-modal-overlay"
      closeTimeoutMS={300}
    >
      <div className="formContainer">
        <h2 className="title">Let's Talk.</h2>
        <span className="closeButton" onClick={handleClose}>&times;</span>
        <form className="form" onSubmit={handleSubmit}>
          <div className={getGroupClassNames("fullName")}>
            <label>Full name</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
          </div>
          <div className={getGroupClassNames("email")}>
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className={getGroupClassNames("phone")}>
            <label>Phone</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className={getGroupClassNames("company")}>
            <label>Company</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
            />
          </div>
          <div className={getGroupClassNames("jobTitle")}>
            <label>Job Title</label>
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </div>
          <div className={getGroupClassNames("message")}>
            <label>What are you building?</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Write your message here"
            />
          </div>
          <button type="submit" className="submitButton">Submit</button>
        </form>
      </div>
    </Modal>
  );
};

export default ContactModal;