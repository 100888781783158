import React from "react"
import Link from "gatsby-link"
import icon1 from "../images/facebook.svg"
import icon2 from "../images/linkedin.svg"
import Fade from "react-reveal/Fade"
import { GrFacebook, GrLinkedin } from "react-icons/gr"

const Footer = ({ link1, link2, link3, button1, button2, button3 }) => {
  return (
    <>
      <div className=" md:py-30 py-15 bg-yellow" id="contact">
        <div className="lg:w-fourteen md:w-ten lg:px-30 mx-auto sm:mt-16 px-5 sm:px-16 sm:px-10">
          <Fade duration={1300}>
            <h2
              className="lg:text-80 md:text-60 text-50 sm:mb-30 lg:-mr-20 mb-15 subline font-knockout text-sand"
              style={{ color: "#FFC20E" }}
            >
              Tech, Brilliantly Delivered
            </h2>
          </Fade>
          <Fade duration={1300}>
            <div className="flex mt-5">
              <div>
                <h3
                  className="font-sofia-bold md:text-1xl text-25 w-5/6 xs:w-full sm:text-30 sm:mb-6 mb-12 lg:text-2xl"
                  style={{ letterSpacing: "-0.7px" }}
                >
                  What are you building? Let's Talk.
                </h3>
              </div>
            </div>
          </Fade>
          <Fade duration={1300}>
            <div className="flex flex-col sm:flex-row">
              <a href="mailto:sales@cider.consulting">
                <button
                  className="text-18 border-black sm:mr-5 text-yellow border bg-black rounded-full leading-relaxed py-1 px-5"
                  style={{ paddingTop: "6px" }}
                >
                  {button1}
                  <span className="arrow">
                    <span style={{ position: "absolute", right: "15px" }}>
                      {" "}
                      &nbsp;➞
                    </span>
                  </span>
                </button>
              </a>
              <Link to={link2} hidden>
                <button
                  className="text-18 border-black border sm:mr-5 sm:my-0 my-3 bg-yellow rounded-full leading-relaxed py-1 px-5"
                  style={{ paddingTop: "6px" }}
                >
                  {button2}
                  <span className="arrow">
                    <span style={{ position: "absolute", right: "15px" }}>
                      {" "}
                      &nbsp;➞
                    </span>
                  </span>
                </button>
              </Link>
              <div>
                <a href="tel:8452620426‬">
                  <button
                    className="text-18 border-black border bg-yellow rounded-full leading-relaxed py-1 px-5"
                    style={{ paddingTop: "6px" }}
                  >
                    {button3}
                    <span className="arrow">
                      <span style={{ position: "absolute", right: "15px" }}>
                        {" "}
                        &nbsp;➞
                      </span>
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </Fade>
          <Fade duration={1300}>
            <div className="flex sm:mt-30 mt-15 sm:items-center sm:flex-row flex-col ">
              <div className="flex">
                <a
                  className="sm"
                  href="https://www.facebook.com/cidertech"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GrFacebook className="transition hover:opacity-100 opacity-60 duration-300 text-black rounded hover:pb-20 text-30 lg:text-32" />
                </a>

                <a
                  className="sm"
                  href="https://www.linkedin.com/company/ciderconsulting"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GrLinkedin className="transition hover:opacity-100 opacity-60 duration-300 text-black mx-6 rounded text-30 lg:text-32" />
                </a>
              </div>
              <p className="text-18 text-read leading-relaxed mt-1 sm:ml-15 sm:mt-0 mt-6">
                Mahwah NJ, United States
              </p>
            </div>
          </Fade>
        </div>
      </div>
      <div
        className="h-15 flex items-center justify-center"
        style={{ background: "#F4BA0E" }}
      >
        <p className="text-18">© 2021 All rights reserved</p>
      </div>
      <noscript>
          <img src="https://ws.zoominfo.com/pixel/qcn3U4v92cUiR2uhUExq" width="1" height="1" style={{display: 'none'}} />
      </noscript>
    </>
  )
}

export default Footer
